import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, InputGroup, Form as RbForm, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../../../actions/auth';
import { backgroundImage } from '../../../../utils/AppConstants';
import { useNavigate } from 'react-router-dom';
import SocialIcons from '../../SocialIcons';
import { setFormView } from '../../../../store/slices/authPageSlice';
import back from '../../../../assets/icons/back.svg';
import { AppDispatch } from '../../../../store/store';
import ForgotPasswordForm from '../../../../components/forgot-password-form/ForgotPasswordForm';
import { resetPassword } from '../../../../store/slices/resetSlice';
import { useTranslation } from 'react-i18next';
import {
  setCandidateExistsError,
  setCandidateTokenExhausted,
} from '../../../../store/slices/candidateSlice';
import EyeIcon from '../../../../components/ui/eye-icon/EyeIcon';
import { toast } from 'react-toastify';
import { LoginForm } from '../../../../models/login-form,interface';
import { checkIfCandidateToken } from '../../../../utils/check-if-candidate-token';

const LoginFormCandidate = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const toolkitDispatch = useDispatch<AppDispatch>();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('errors.emailInvalid'))
      .required(t('errors.emailRequired')),
    password: Yup.string().required(t('errors.passwordRequired')),
  });

  const initialValues: LoginForm = {
    email: '',
    password: '',
  };

  const handleSubmit = async (
    values: LoginForm,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const getTokenResponse = await dispatch(
        login(values.email, values.password)
      );

      if (!getTokenResponse.access && !getTokenResponse.refresh) return;

      toolkitDispatch(setCandidateTokenExhausted(false));

      if (checkIfCandidateToken()) {
        dispatch(setCandidateExistsError(false));
        // Push the event to dataLayer upon success
        if (window.dataLayer) {
          window.dataLayer.push({
          'event': 'SignIn'
        });
        }
        navigate('/candidate');
      } else {
        dispatch(setCandidateExistsError(true));
        toast.error(t('messages.candidateNotExists'));
      }
    } catch (error) {
      setSubmitting(false);
    }
  };

  const toggleForgotShow = () => {
    setIsForgotPassword((prev) => !prev);
  };

  const handleForgotPasswordSubmit: (email: string) => void = (
    email: string
  ) => {
    toolkitDispatch(resetPassword(email));
  };

  return (
    <>
      <div
        className='bg-image login h-100 w-100 d-flex justify-content-center align-items-center'
        style={backgroundImage}
      >
        <div className={`main${isForgotPassword ? ' w-100' : ''}`}>
          <button
            onClick={() => {
              setIsForgotPassword(false);
              toolkitDispatch(setFormView(false));
            }}
            className='auth-back'
          >
            <img src={back} alt='back' />
          </button>
          {!isForgotPassword && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({ values, isSubmitting, isValid }) => (
                <Form className='form'>
                  <h2 className='register text-center'>
                    {t('loginForm.title')}
                  </h2>
                  <p className='confrom text-center'>
                    {t('loginForm.subtitle')} {}
                    <span
                      className='cursor-pointer'
                      onClick={() => navigate('/auth/candidate/sign-up')}
                    >
                      {t('loginForm.registrationUrl')}
                    </span>
                  </p>
                  <Row className='mt-2 text-start'>
                    <RbForm.Group
                      as={Col}
                      xs={12}
                      controlId='validationCustom01'
                    >
                      <RbForm.Label className='input-label'>
                        {t('loginForm.emailLabel')}
                      </RbForm.Label>
                      <InputGroup>
                        <Field
                          className='form-control auth-input'
                          type='text'
                          placeholder={t('loginForm.emailPlaceholder')}
                          aria-describedby='inputGroupPrepend'
                          name='email'
                        />
                      </InputGroup>
                      <ErrorMessage
                        name='email'
                        component='p'
                        className='text-danger position-absolute error-message'
                      />
                    </RbForm.Group>
                    <RbForm.Group
                      as={Col}
                      xs={12}
                      controlId='validationCustom02'
                    >
                      <RbForm.Label className='input-label'>
                        {t('loginForm.passwordLabel')}
                      </RbForm.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control auth-input'
                          type={showPassword ? 'text' : 'password'}
                          placeholder={t('loginForm.passwordPlaceholder')}
                          name='password'
                          autoComplete='on'
                        />
                        <EyeIcon
                          isOpen={showPassword}
                          action={() => setShowPassword((prev) => !prev)}
                        />
                      </InputGroup>
                      <ErrorMessage
                        name='password'
                        component='p'
                        className='text-danger position-absolute error-message'
                      />
                    </RbForm.Group>
                  </Row>
                  <p className='confrom text-end'>
                    <span
                      className='cursor-pointer'
                      onClick={() => setIsForgotPassword((prev) => !prev)}
                    >
                      {t('loginForm.passwordReset')}
                    </span>
                  </p>
                  <Button
                    disabled={!isValid}
                    className='w-100 primary-button p-3'
                    type='submit'
                  >
                    {t('auth.loginButton')}
                  </Button>
                </Form>
              )}
            </Formik>
          )}

          {isForgotPassword && (
            <ForgotPasswordForm
              handleSubmit={handleForgotPasswordSubmit}
              changeShowState={toggleForgotShow}
            />
          )}
          {/*
          <h3>{t('loginForm.loginProposal')}</h3>
          <SocialIcons />
          */}
        </div>
      </div>
    </>
  );
};

export default LoginFormCandidate;
