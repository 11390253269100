import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import VacancyForm from '../../../../models/vacancy-form.interface';
import VacancyExperience from '../../../../models/vacancy-experience';
import VacancyInfo from '../../../../components/ui/vacancy-info/VacancyInfo';
import Language from '../../../../models/language.interface';
import { getLanguageLevel } from '../../../../utils/get-language-level';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import './OfferSubmitModal.scss';
import { salaryRange } from '../../../../utils/format-salary';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import {getCurrentLevelEducationName} from "../../../../utils/get-current-level-education-name";

interface Props {
  show: boolean;
  close: () => void;
  vacancyForm: VacancyForm;
  handleSubmitClick: () => void;
}

const OfferSubmitModal = ({
  show,
  close,
  vacancyForm,
  handleSubmitClick,
}: Props) => {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const languages = useSelector(
    (state: RootState) => state.languageReducer.languages
  );
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });
  const sectorOptions: ListItem[] = t('sectorOptions', { returnObjects: true });
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });
  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });
  const educationLevelList: ListItem[] = t('educationLevelList', {
    returnObjects: true,
  });

  return (
    <Modal show={show} size='lg' onHide={close}>
      <Modal.Header closeButton className='modal-header-color'>
        <Modal.Title>{t('createOffer.submitModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='offer-modal-row'>
          <Col xs={12}>
            <h2 className='page-heading-2 fw-bold mt-2 mb-4'>
              {vacancyForm.position}
            </h2>
            <h2 className='offer-form-header fw-bold mt-4 mb-4'>
              {t('companyVacancyDetails.title')}
            </h2>

            <Row>
              <Col md={6}>
                <VacancyInfo
                  title={t('companyVacancyDetails.companyNameLabel') + ':'}
                  info={company.name}
                />
              </Col>
              <Col md={6}>
                <VacancyInfo
                  title={t('companyVacancyDetails.sector') + ':'}
                  info={getItemById(sectorOptions, vacancyForm.sector)}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6}>
                <VacancyInfo
                  title={t('companyVacancyDetails.position') + ':'}
                  info={vacancyForm.position}
                />
              </Col>
              <Col md={6}>
                <VacancyInfo
                  title={t('companyVacancyDetails.vacanciesAmount') + ':'}
                  info={vacancyForm.number_of_vacancies}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6}>
                <VacancyInfo
                  title={t('vacancyDetails.labels.range') + ':'}
                  info={salaryRange(
                    +vacancyForm.salary_min,
                    +vacancyForm.salary_max,
                    t('salary.minPrefix'),
                    t('salary.maxPrefix')
                  )}
                />
              </Col>
              <Col md={6}>
                <VacancyInfo
                  title={t('vacancyDetails.labels.type') + ':'}
                  info={getItemById(jobTypeList, vacancyForm.job_type)}
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={12}>
                <VacancyInfo
                  title='Descripción:'
                  info={vacancyForm.description}
                />
              </Col>
            </Row>

            <h2 className='offer-form-header fw-bold mt-4 mb-4'>
              {t('vacancyDetails.infoTitle') + ':'}
            </h2>
            {vacancyForm.offerexperience.length > 0 && (
              <>
                <h6 className='offer-form-header mt-5 mb-4'>
                  {t('vacancyDetails.subtitles.experience')}
                </h6>

                {vacancyForm.offerexperience.map(
                  (experience: VacancyExperience, index: number) => (
                    <Row className='mt-3' key={index}>
                      <Col md={6}>
                        <VacancyInfo
                          title={t('vacancyDetails.labels.experience') + ':'}
                          info={experience.position}
                        />
                      </Col>
                      <Col md={6}>
                        <VacancyInfo
                          title={
                            t('vacancyDetails.labels.experienceAmount') + ':'
                          }
                          info={`${experience.duration} ${
                            experience.duration === '1'
                              ? t('time.singularYear')
                              : t('time.pluralYear')
                          }`}
                        />
                      </Col>
                    </Row>
                  )
                )}
              </>
            )}

            {vacancyForm.offerlanguage.length > 0 && (
              <>
                <h6 className='offer-form-header mt-5 mb-4'>
                  {t('vacancyDetails.subtitles.language')}
                </h6>

                {vacancyForm.offerlanguage.map(
                  (language: Language, index: number) => (
                    <Row className='mt-3' key={index}>
                      <Col md={6}>
                        <VacancyInfo
                          title={t('vacancyDetails.labels.language') + ':'}
                          info={
                            languages.filter(
                              (lang) => language.language === lang.id
                            )[0].name
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <VacancyInfo
                          title={t('vacancyDetails.labels.languageLevel') + ':'}
                          info={getLanguageLevel(+language.level, levels)}
                        />
                      </Col>
                    </Row>
                  )
                )}
              </>
            )}

            {(vacancyForm.offerqualities.length > 0 ||
              vacancyForm.offerqualities.length > 0) && (
              <>
                <h6 className='offer-form-header mt-5 mb-4'>
                  {t('vacancyDetails.subtitles.skill')}
                </h6>

                <Row className='mt-3'>
                  <Col md={6}>
                    {vacancyForm.offerqualities.length > 0 && (
                      <div className='details__list'>
                        <p>{t('vacancyDetails.labels.quality')}: </p>
                        <ul>
                          {vacancyForm.offerqualities.map((item, index) => {
                            return (
                              <li key={item.id || index}>
                                {getItemById(aptitudeList, item.id)}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    {vacancyForm.offercapabilitie.length > 0 && (
                      <div className='details__list'>
                        <p>{t('vacancyDetails.labels.capability')}: </p>
                        <ul>
                          {vacancyForm.offercapabilitie.map((item, index) => (
                            <li key={index}>{item.name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}

            {vacancyForm.drivingLicense.license.length > 0 && (
              <>
                <h6 className='offer-form-header mt-5 mb-4'>
                  {t('vacancyDetails.subtitles.license')}
                </h6>

                <Row className='mt-3'>
                  <Col md={12}>
                    <VacancyInfo
                      title={t('vacancyDetails.labels.license') + ':'}
                      info={vacancyForm.drivingLicense.license
                        .map((item) => item.name)
                        .join(' ')}
                    />

                    <VacancyInfo
                      title={t('licenseForm.vehicleLabel') + ':'}
                      info={
                        vacancyForm.drivingLicense.isVehicle
                          ? t('licenseForm.secondOption')
                          : t('licenseForm.firstOption')
                      }
                    />
                  </Col>
                </Row>
              </>
            )}
            {vacancyForm.offertraining.length > 0 && (
              <>
                <h6 className='offer-form-header mt-5 mb-4'>
                  {t('vacancyDetails.subtitles.education')}
                </h6>
                <Row className='mt-3'>
                  <Col md={12}>
                    <VacancyInfo
                      title={t('vacancyDetails.labels.education') + ':'}
                      info={
                        getItemById(educationLevelList, vacancyForm.offertraining?.[0].level) + " - " +
                          vacancyForm.offertraining[0].name.map((item) => (
                           getCurrentLevelEducationName(vacancyForm.offertraining[0].level, item, '')
                          ))
                      }
                    />
                  </Col>
                </Row>
              </>
            )}

            <h6 className='offer-form-header mt-5 mb-4'>
              {t('vacancyDetails.subtitles.location')}
            </h6>
            <Row className='mt-3'>
              <Col md={12}>
                <VacancyInfo
                  title={t('vacancyDetails.labels.location') + ':'}
                  info={vacancyForm.location}
                />
              </Col>
            </Row>

            <h6 className='offer-form-header mt-5 mb-4'>
              {t('companyVacancyDetails.endDate')}
            </h6>
            <Row className='mt-3'>
              <Col md={12}>
                <VacancyInfo
                  title={t('companyVacancyDetails.endDateLabel') + ':'}
                  info={vacancyForm.end_date}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='px-5 mt-5 mb-5'>
          <Col md={6}>
            <button
              type='button'
              onClick={close}
              className='btn btn-outline-primary btn-outline-color w-100 py-3'
            >
              {t('editButton')}
            </button>
          </Col>
          <Col md={6}>
            <button
              type='button'
              className='btn btn-primary primary-btn-color w-100 py-3'
              onClick={handleSubmitClick}
            >
              {t('createOffer.submitModal.submit')}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default OfferSubmitModal;
