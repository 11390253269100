import React, { useState, useEffect } from 'react';

import './ProfileCompany.scss';
import { useNavigate } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import ApiService from '../../../core/services/network.service';
import * as endpoints from '../../../constants/endpoints';
import tokenService from '../../../_services/token.service';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { setUpdatedCompanyData } from '../../../store/slices/companySlice';
import { checkIfNumber } from '../../../utils/check-if-number';
import companyImage from '../../../assets/icons/avatar-placeholder.svg';
import { decodeToken } from '../../../models/decode-token';
import { checkIfLetter } from '../../../utils/check-if-letter';
import { readUploadedFile } from '../../../utils/read-uploaded-file';
import { acceptableFileSize } from '../../../utils/acceptable-file-size';
import { toast } from 'react-toastify';
import { maxCompanyCoverSize, maxLogoSize } from '../../../constants/constants';

const ProfileDetailCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [companyDetail, setCompanyDetail] = useState<any>();
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState<any>();
  const [coverPicture, setCoverPicture] = useState<any>();
  const [companyData, setCompanyData] = useState<any>();

  const token = tokenService.getLocalAccessToken();
  const decodedToken = decodeToken(token);
  const handleCloseFinalModal = () => setShowFinalModal(false);
  const handleShowFinalModal = () => setShowFinalModal(true);
  const initialData: any = {
    id: '',
    name: '',
    sector: '',
    url: '',
    nif: '',
    business_name: '',
    address: '',
    description: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: 0,
    logo: null,
    cover: null,
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => {
      onSubmitData(values, decodedToken?.company_id);
    },
  });

  useEffect(() => {
    if (token && decodedToken && decodedToken.company_id) {
      fetchCompanyById(decodedToken?.company_id);
    }
  }, []);

  const fetchCompanyById = (id: string) => {
    ApiService.request<any>(
      'GET',
      endpoints.COMPANY_DETAIL.replace('{id}', id)
    ).then((res) => {
      setCompanyDetail(res?.body);
      const data = res?.body;

      if (data) {
        formik.setValues({
          ...initialData,
          id: data?.id,
          name: data?.name,
          sector: data?.sector,
          url: data?.url,
          nif: data?.nif,
          country: data?.country,
          business_name: data?.business_name,
          address: data?.address,
          description: data?.description,
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          phone: data?.phone,
          logo: data?.logo,
          cover: data?.phone,
        });
      }
      setCompanyData(res?.body);
      setCoverPicture({
        preview: null,
      });
      setProfilePicture({
        preview: null,
      });
    });
  };

  const onSubmitData = (data: any, id: any) => {
    if (typeof data?.logo == 'string') {
      delete data?.logo;
    }
    if (typeof data?.cover == 'string') {
      delete data?.cover;
    }
    ApiService.request<any>(
      'PUT',
      endpoints.COMPANY_DETAIL.replace('{id}', id),
      data,
      data?.logo || data?.cover
    ).then((res) => {
      setCompanyDetail(res?.body);
      const data = res?.body;

      if (data) {
        dispatch(setUpdatedCompanyData(data));
        formik.setValues({
          ...initialData,
          id: data?.id,
          name: data?.name,
          sector: data?.sector,
          url: data?.url,
          nif: data?.nif,
          country: data?.country,
          business_name: data?.business_name,
          address: data?.address,
          description: data?.description,
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          phone: data?.phone,
          logo: data?.logo,
          cover: data?.phone,
        });
        setCompanyData(res?.body);
        ApiService.showSuccessToast(t('messages.successfullyUpdated'));
      }
    });
  };
  const handleFileChange = (e: any) => {
    const file: any = e.target.files[0];
    if (!file) return;
    handleFileUpload(file, 'logo', maxLogoSize, setProfilePicture);
  };

  const handleCoverChange = (e: any) => {
    const file: any = e.target.files[0];
    if (!file) return;
    handleFileUpload(file, 'cover', maxCompanyCoverSize, setCoverPicture);
  };

  function handleFileUpload(
    file: File,
    formikField: string,
    maxSize: number,
    setFunction: Function
  ) {
    if (!acceptableFileSize(file.size, maxSize)) {
      toast.error(`${t('messages.sizeLimitError')} ${maxSize} MB`);
      return;
    }

    readUploadedFile(file, setFunction);
    formik.setFieldValue(formikField, file);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='zestur-card p-0'>
        <div className='candidate-profile-section'>
          <div className='candidate-profile-section-holder'>
            <Image
              src={
                coverPicture && coverPicture?.preview
                  ? coverPicture?.preview
                  : companyData?.cover
              }
            />
            <label
              htmlFor='upload-cover-button'
              className='btn btn-edit-profile'
            >
              {t('editButton')}
            </label>
            <input
              type='file'
              id='upload-cover-button'
              style={{ display: 'none' }}
              onChange={handleCoverChange}
            />
          </div>
          <div className='profile-picture'>
            <label htmlFor='upload-logo-button'>
              <Image
                className='object-fit-cover details__logo'
                src={
                  profilePicture && profilePicture?.preview
                    ? profilePicture?.preview
                    : companyData?.logo || companyImage
                }
              />
            </label>
            <input
              type='file'
              id='upload-logo-button'
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
          <div className='candidate-bio d-flex align-items-center justify-content-between px-3'>
            <div className='w-200px'></div>
            <div className='d-flex align-items-center w-100 justify-content-between'>
              <div className='d-flex flex-column'>
                <h2>{companyData?.name}</h2>
                <small>{companyData?.business_name}</small>
              </div>
              <div className='d-flex align-items-center'>
                <button
                  type='submit'
                  className='btn btn-primary primary-btn-color'
                >
                  {t('submitButton')}
                </button>
                <button
                  type='button'
                  onClick={() => navigate('/company')}
                  className='btn btn-outline-primary ms-4 tablet-hidden'
                >
                  {t('cancelButton')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='pb-3 px-3'>
          <Row>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('companyRegistration.nameLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('companyRegistration.sectorLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='sector'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sector}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={12}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('companyEditPage.urlLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='url'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.url}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('companyEditPage.nifLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='nif'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nif}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('companyEditPage.bussinessNameLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='business_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.business_name}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={12}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('experienceForm.location')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='address'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={12}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('vacancyLocationPage.description')}
                </Form.Label>
                <textarea
                  className='form-control vacancy-edit-input'
                  placeholder=''
                  rows={6}
                  id='floatingTextarea'
                  name='description'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          <h2 className='contact-title mt-5 mb-5'>
            {t('companyEditPage.infoLabel')}
          </h2>
          <Row className='mt-4'>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('candidateInfoComponent.name')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='first_name'
                  onChange={(e) => {
                    const { value } = e.target;

                    if (!value) {
                      formik.setFieldValue('first_name', value);
                      return;
                    }
                    if (checkIfLetter(value)) {
                      formik.setFieldValue('first_name', value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('candidateInfoComponent.surname')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='last_name'
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!value) {
                      formik.setFieldValue('last_name', value);
                      return;
                    }
                    if (checkIfLetter(value)) {
                      formik.setFieldValue('last_name', value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('candidateRegistration.emailLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  name='email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='mb-2 app-label'>
                  {t('companyEditPage.phoneLabel')}
                </Form.Label>
                <Form.Control
                  className='form-control vacancy-edit-input'
                  type='text'
                  placeholder=''
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length === 1 && value === '+') {
                      formik.setFieldValue('phone', '+');
                      return;
                    }
                    if (checkIfNumber(value.slice(1))) {
                      formik.setFieldValue('phone', value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default ProfileDetailCompany;
