import { createSlice } from '@reduxjs/toolkit';
import Option from '../../models/option.interface';

const LICENSE_CLASSES: Option[] = [
  { name: 'No dispongo', selected: true },
  { name: 'AM' },
  { name: 'A1' },
  { name: 'A2' },
  { name: 'A' },
  { name: 'B' },
  { name: 'C1' },
  { name: 'C' },
  { name: 'D1' },
  { name: 'D' },
  { name: 'BE' },
  { name: 'C1E' },
  { name: 'CE' },
  { name: 'D1E' },
  { name: 'DE' },
  { name: 'BTP' },
  { name: 'LCM' },
  { name: 'LVA' },
  { name: 'ADR' },
];

interface LicenseState {
  licenseList: Option[];
  isVehicle: boolean;
}

const initialState: LicenseState = {
  licenseList: LICENSE_CLASSES,
  isVehicle: false,
};

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setIsVehicle: (state, action) => {
      state.isVehicle = action.payload;
    },

    setLicenseList: (state) => {
      state.licenseList.map((item) => (item.selected = false));
      state.licenseList[0].selected = true;
    },

    updateLicenseListSelection: (state, action) => {
      action.payload.map((level: string) => {
        state.licenseList.map((license) => {
          if (level === license.name) {
            state.licenseList[0].selected = false;
            license.selected = true;
          }
        });
      });
    },

    licenseOptionSelect: (state, action) => {
      const selectedOptionIndex = state.licenseList.findIndex(
        (item) => item.name === action.payload
      );
      state.licenseList[0].selected = false;
      state.licenseList[selectedOptionIndex].selected =
        !state.licenseList[selectedOptionIndex].selected;

      if (!state.licenseList.some((item) => item.selected)) {
        state.licenseList[0].selected = true;
      }
    },

    updateLicenseTranslation: (state, action) => {
      state.licenseList[0].name = action.payload;
    },

    resetLicense: () => initialState,
  },
});

export const {
  setIsVehicle,
  setLicenseList,
  licenseOptionSelect,
  updateLicenseListSelection,
  updateLicenseTranslation,
  resetLicense,
} = licenseSlice.actions;
export default licenseSlice.reducer;
