import React from 'react';
import { useState } from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import { backgroundImage } from '../../../utils/AppConstants';
import Step from '../../../models/step.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import EyeIcon from '../../../components/ui/eye-icon/EyeIcon';
import { boolean } from 'yup';
import {
  createCompany,
  setCompanyPassword,
  setTerms,
} from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import { getPasswordSchema } from '../../../utils/get-password-schema';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';

function RegistrationStep6(props: Step) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const company = useSelector((state: RootState) => state.companyReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const logoURL: string = `${ASSETS_URL}`;

  const passSchema = getPasswordSchema();
  const validationSchema = passSchema.shape({
    terms: boolean().oneOf([true], t('errors.termsNotAccepted')),
  });

  return (
    <div className='bg-white register-form'>
      <Row>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={100}
            startStep={6}
            endStep={6}
          />
        </Col>
        <Col xs={12} md={3} className='mobile-hidden'>
          <div className='register-sidebar' style={backgroundImage}>
            <Image
              className='ms-3 mt-3'
              src={logoURL + logo}
              alt='logo Image'
              fluid
            />
          </div>
        </Col>

        <Col xs={12} className='no-mobile-hidden'>
          <div className='text-main-black px-4'>
            <h2>{t('companyRegistration.step6.title')}</h2>
            <p>{t('companyRegistration.step6.description')}</p>
          </div>
        </Col>

        <Col
          xs={12}
          md={9}
          className='d-flex justify-content-center justify-items-center'
        >
          <Formik
            initialValues={{
              password: company.password,
              confirmPassword: company.password,
              terms: company.terms,
            }}
            onSubmit={(values) => {
              dispatch(setCompanyPassword(values.password));
              dispatch(setTerms(values.terms));
              dispatch(createCompany()).then(() => {
                // Push the event to dataLayer upon success
              if (window.dataLayer) {
                window.dataLayer.push({
                 'event': 'SignUp'
                });
              }
                props.handleNext();
              });
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                {/* main-ProgressBar */}
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={6}
                    endStep={6}
                    progressValue={100}
                  />
                  <h2>{t('companyRegistration.step6.title')}</h2>
                  <p>{t('companyRegistration.step6.description')}</p>
                </div>

                {/* form-fields */}
                <Row className='form-fields candidate'>
                  <Row>
                    <Form.Group as={Col} xs={12} controlId='validationCustom01'>
                      <Form.Label className='label'>
                        {t('companyRegistration.step6.passwordLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type={showPassword ? 'text' : 'password'}
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='password'
                          autoComplete='on'
                        />
                        <EyeIcon
                          top={24}
                          right={18}
                          isOpen={showPassword}
                          action={() => setShowPassword((prev) => !prev)}
                        />
                        {errors.password && touched.password ? (
                          <p className='text-danger error-message candidate'>
                            {errors.password}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      controlId='validationCustom01'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step6.confirmLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          name='confirmPassword'
                          type={showConfirmationPassword ? 'text' : 'password'}
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          autoComplete='on'
                        />
                        <EyeIcon
                          top={24}
                          right={18}
                          isOpen={showConfirmationPassword}
                          action={() =>
                            setShowConfirmationPassword((prev) => !prev)
                          }
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <p className='text-danger error-message candidate'>
                            {errors.confirmPassword}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      controlId='validationCustom01'
                      className='mobile-mt-2'
                    >
                      {['checkbox'].map((type: any) => (
                        <div key={`default-${type}`} className='mb-3'>
                          <label
                            className={`register-checkbox position-relative${
                              errors.terms && touched.terms ? ' mb-4' : ''
                            }`}
                          >
                            <Field type='checkbox' name='terms' />{' '}
                            <span className='checkbox-text'>
                              {t('companyRegistration.step6.termsLabel')}
                            </span>
                            {errors.terms && touched.terms ? (
                              <p className='text-danger error-message candidate checkbox-error'>
                                {errors.terms}
                              </p>
                            ) : null}
                          </label>
                          <label className='register-checkbox mt-2'>
                            <Field name='comunication' type='checkbox' />
                            <span className='checkbox-text'>
                              {t('companyRegistration.step6.subscriptionLabel')}
                            </span>
                          </label>
                        </div>
                      ))}
                    </Form.Group>
                  </Row>
                </Row>

                <Row className='mt-4 no-mobile-hidden'></Row>

                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep6;
